<template>
    <div class="card">
        <div class="table-responsive">
            <table class="table">
                <thead>
                <tr>
                    <th>Invoice no</th>
                    <th>Invoice Date</th>
                    <th>Patient ID</th>
                    <th>Patient Name</th>
                    <th>Patient Mobile No</th>
                    <th>Admission No</th>
                    <th class="text-right">Total Amount</th>
                    <th>OPD</th>
                    <th>HOSPITAL SERVICE</th>
                    <th>INVESTIGATION</th>
                    <th>MEDICINE</th>
                    <th>DENTAL</th>
                    <th>EYE</th>
                    <th>Not Specified</th>
                </tr>
                </thead>
                <tbody>
                    <tr>
                        <th>INV-2023-457</th>
                        <th></th>
                        <th>357</th>
                        <th>Abu Salah</th>
                        <th>0199999</th>
                        <th>IPD-2023-34</th>
                        <th class="text-right">2300</th>
                        <th>Opd text</th>
                        <th></th>
                        <th></th>
                        <th>Alcet</th>
                        <th></th>
                        <th></th>
                        <th></th>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script setup>

</script>