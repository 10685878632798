<template>
    <div class="card">

        <div class="bg-blue-light p-1">
            <TitleButton 
                :showBtn="true"
                :showAddNew="false"
                title="Hospital Bill"
                @onClickCloseButton="goToList"
            />
        </div>

        <div class="my-2 px-2">
            <DateQuerySetter
                btnTitle="Go"
                :isVisible="false"
            >
            </DateQuerySetter>
        </div>

        <div class="col-12 px-2">
            <ListTable />
        </div>

    </div>
</template>

<script setup>
    import TitleButton from '@/components/atom/TitleButton';
    import { useRouter } from 'vue-router';
    import DateQuerySetter from '@/components/atom/DateQuerySetter';
    import ListTable from '@/components/molecule/company/hospital/hospital-admin/SalesReportUnitwiseTable.vue';

    const $router = useRouter();

    const goToList = () => {
        $router.back();
    }
</script>